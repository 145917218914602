import React from 'react';
import {DropdownItem} from "reactstrap";

const LanguageItem = ({language, switchLanguage, handleRequestClose}) => {
    return (
        <DropdownItem onClick={() => {
            handleRequestClose();
            switchLanguage(language);
        }}>
            <span>{language.name}</span>
        </DropdownItem>
    );
};

export default LanguageItem;
